.fyb--sec {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 64px 0px 0px;
    gap: 64px;
    background: #FFFFFF;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}
.fyb--hdrg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 24px;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}
.fyb--t {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}
.fyb--hdrg-t {
    font-family: 'Asap';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 48px;
    color: #000732;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;

    text-align: center;

    padding-bottom: 18px;

    /* OnTablet */
    @media only screen and (min-width: 768px) and (max-width: 1200px) {

    }
    /* OnMobile */
    @media only screen and (min-width: 0px) and (max-width: 767px) {
        font-size: 34px;
    }
}
.fyb--shdrg-t {
    font-family: 'Asap';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    color: #000732;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;

    text-align: center;
    
    /* OnTablet */
    @media only screen and (min-width: 768px) and (max-width: 1200px) {

    }
    /* OnMobile */
    @media only screen and (min-width: 0px) and (max-width: 767px) {
        font-size: 22px;
        padding: 0px 10px 0px 10px;
    }
}
.fyb--wrp {
    /* display: flex; */
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 12px;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;

    width: 34vw;

    /* OnTablet */
    @media only screen and (min-width: 768px) and (max-width: 1200px) {
        width: 50vw;
    }
    /* OnMobile */
     @media only screen and (min-width: 0px) and (max-width: 767px) {
        width: 85vw;
    }
}
.fyb--s-b {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 5px;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}
.fyb--s-b:nth-child(2) {
    padding-top: 18px;
}
.fyb--geo-btn {
    display: flex!important;
    flex-direction: row!important;
    justify-content: center!important;
    align-items: center!important;
    padding: 8px 24px!important;
    gap: 10px!important;
    background: #40466a!important;
    border-radius: 14px!important;

    flex: none;
    flex-grow: 1;

    font-family: 'Asap';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FBFBFF;
}
.fyb--geo-btn:hover {
    background: #ABC4F0!important;;
    border-color: #ABC4F0!important;;
    border-radius: 14px 14px 14px 14px!important;
    color: #000732!important;
}
.fyb--s-i {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 34px!important;
    gap: 10px;
    background: #FBFBFF!important;
    border: 1px solid #000732!important;
    border-radius: 14px 0px 0px 14px!important;

    font-family: 'Asap';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #7E8199;

    flex: none;
    flex-grow: 1;
}
.fyb--s-ic {
    position: absolute;
    left: 1.86%;
    right: 2.86%;
    top: 26%;
    bottom: 0%;
    /*this will keep the icon appearing all time, even when on input::focus*/
    z-index: 99; 
}
.fyb--s-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 24px!important;
    gap: 10px;
    background: #40466a!important;
    border-radius: 0px 14px 14px 0px!important;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;

    font-family: 'Asap';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FBFBFF!important;
}
.fyb--s-btn:hover {
    background: #ABC4F0!important;
    border-color: #ABC4F0!important;
    border-radius: 0px 14px 14px 0px!important;
    color: #000732!important;
}
.fyb--i {
    display: flex;
    flex-direction: row;
    flex: none;
    flex-grow: 0;
    /* width: 100%; */
    /* height: auto; */
    /* background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../../assets/images/promo-starter/starter-page.png"); */

    /* OnMobile */
    @media only screen and (min-width: 0px) and (max-width: 767px) {
        width: 85%;
        margin-left: auto;
        margin-right: auto;
    }
} 
.fyb--dd {
    background-color: #FBFBFF;
    display: flex;
    flex-direction: column;
    border: 1px solid #495057;
    border-radius: 14px;
}  
.fyb--dd:empty {
    border: none;
}
.fyb--dd-r {
    cursor: pointer;
    text-align: start;
    margin: 2px 0;
    border-bottom: 1px solid #495057;
    flex-direction: row;
}
.fyb--dd-n {
    padding-left: 10px;
}
.fyb--dd-r:last-of-type {
    border: none;
}
.fyb--dd-ic {
    /* display: flex; */
    /* flex-direction: row; */
    padding: 6px 8px;
    gap: 10px;

    /* Black */
    background: #000732!important;
    border-radius: 14px 0px 14px 8px!important;
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

/* OnTablet */
@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .fyb--ii {
        width: 100vw;
        height: auto; 
    } 
}
/* OnMobile */
@media only screen and (min-width: 0px) and (max-width: 767px) {
    .fyb--ii {
        width: 100%;
        height: auto; 
    } 
} 