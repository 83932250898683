.ps {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 180px 64px;
    gap: 32px;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}
.ps--f {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-self: center;
    padding: 0px;
    /* gap: 32px; */

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;

    width: 100vw;
    padding: 0px 180px;
}
.ps--f-i {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 10px;
    gap: 32px;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 1;

    flex-basis: 50%;
}
.ps--f-i-b {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}
.ps--f-i-hdr {
    font-family: 'Asap';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #000732;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}
.ps--f-i-con {   
    font-family: 'Asap';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000732;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 1;
}
.ps--f-img {
    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
    
    /* max-width: 50%; */
    /* height: auto; */
}
/* OnTablet */
@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .ps--f {
        padding: 0px 80px;
    }
}
/* OnMobile */
@media only screen and (min-width: 0px) and (max-width: 767px) {
    .ps {
        padding: 24px 24px 144px;
    }
    .ps--f {
        flex-direction: column;
        padding: 0px 24px;
        gap: 32px;
    }
    .ps--f-i-b {
        text-align: center;
    }
    .ps--f-i-hdr {
        font-size: 22px;
        line-height: 24px;
    }
    .ps--f-i-con {   
        font-size: 16px;
        line-height: 22px;
    }
    .ps--f-i {
        padding: 0px 10px;
    }
    /* .ps--f-img {        
        max-width: 90%;
        height: auto;
    } */
}




/* --- --- --- --- --- */
/* --- --- --- --- --- */
/* Development CSS     */
/* --- --- --- --- --- */
/* --- --- --- --- --- */
/* .ps--f {    
    border: 4px solid blue;
}
.ps--f-i:nth-child(2) {
    border: 4px solid red;
}
.ps--f-i {
    border: 4px solid green;
} */