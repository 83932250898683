.ab--u-f {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    /* align-self: center; */
    padding: 64px 180px;
    gap: 32px;
    background: #FFFFFF;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}
.ab--u-h {
    font-family: 'Asap';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    text-align: center;
    color: #000732;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}
.ab--us-t {
    font-family: 'Asap';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000732;
    padding: 0 15%;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}
/* OnTablet */
@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .ab--u-f {
        padding: 64px 24px;
        /* padding-top: 0; */
    }
}
/* OnMobile */
@media only screen and (min-width: 0px) and (max-width: 767px) {
    .ab--u-f {
        padding: 64px 0px;
        /* padding-top: 0; */
    }
    .ab--us-t {
        padding: 0 10%;
    }
}