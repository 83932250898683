.cp--con {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    /* align-items: center;  */
    align-self: stretch;
    
    padding: 4px 180px 64px 180px;
    gap: 32px;
    flex: none;
    flex-grow: 0;

    font-family: 'Asap';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000732;

    /* margin-top: -24px; */

    /* border: 4px solid red; */
}
.cp--con:nth-child(2) {
    /* padding: 4px 180px; */
    padding-bottom: 64px;
}
.cp--cnt {
    font-family: 'Asap';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000732;
    align-self: stretch;
    
    flex: none;
    flex-grow: 0;
}
.cp--cnt-filters {
    /* display: flex; */
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;

    width: 330px; /* Dodane, aby zabezpieczyć szerokość maksymalną */
    overflow-wrap: break-word; /* Umożliwia łamanie długich słów */
    word-wrap: break-word; /* Wcześniejsza wersja overflow-wrap dla kompatybilności */
    white-space: normal; /* Domyślne zachowanie zawijania tekstu */
    /*overflow-x: auto;*/ /* Pokazuje pasek przewijania, jeśli tekst nadal wychodzi poza */
    
    padding: 24px 24px 12px 24px;
    gap: 12px;
    flex-shrink: 0;
    border-radius: 14px;
    border: 1px solid var(--Hover-Card, #F0F4FA);
    background: var(--White, #FBFBFF);
    box-shadow: 0px 4px 48px 0px rgba(12, 12, 12, 0.08);

    overflow: hidden;

    /* z-index: 100; */
    
    /* border: 4px solid blue; */
}
.cp--cnt-activities {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    align-self: flex-start;
    gap: 32px;
    flex: 1 0 0;

    /* width: 430px; */
    /* width: auto; */
    /* min-width: 750px; */

    /* border: 4px solid green; */
}
/* OnTablet */
@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .cp--con {
        /* flex-direction: column; */
        padding: 0px 60px;
    }
    .cp--con ol {
        padding-left: 15px;
        /* list-style-type: none; */
        /* list-style-position: inside; */
    }
}
/* OnMobile */
@media only screen and (min-width: 0px) and (max-width: 767px) {
    .cp--con {
        flex-direction: column;
        padding: 0px 24px;
    }
    .cp--con ol {
        padding-left: 15px;
        /* list-style-type: none; */
        /* list-style-position: inside; */
    }
    .cd-cnt-filters {
        width: 100%;
    }
    .cd-cnt-activities {
        width: 100%;
    }
}
