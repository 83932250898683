/* Map */
.location-map--sec-l-mai {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;

    /* width: 726px; */
    width: 100%;
    /* max-height: 1678px; */

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    z-index: 6;
}
.location-map--sec-l-mai-som {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0px;
    gap: 24px;

    /* width: 726px; */
    width: 100%;
    /* max-height: 1678px; */

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    z-index: 6;

    /* margin-top: -46px */
}
.location-map--sec-l-mai-h {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}
.location-map--sec-l-mai-h h2 {
    /* width: 726px; */
    height: 29px;

    font-family: 'Asap';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;

    color: #000732;

    flex: none;
    order: 0;
    flex-grow: 0;
    z-index: 0;
}
.location-map--sec-l-mai-map {
    /* width: 726px; */
    /* height: 298px; */

    border-radius: 14px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    z-index: 1;
}
.location-map--sec-l-mai-btn {
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 14px;
    background: var(--primary-blue, #40466a);
    border: 1 solid #40466a;
}
.location-map--sec-l-mai-btn-f {
    /* width: 24px;
    height: 24px; */

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}
.location-map--sec-l-mai-btn-ico {
    left: 8.33%;
    right: 8.33%;
    top: 12.5%;
    bottom: 12.5%;
    /* White */
    /* border: 1.8px solid #FBFBFF; */
}
.location-map--sec-l-mai-btn-t {
    color: var(--white, #FBFBFF);
    /* Body/Cards */
    font-size: 14px;
    font-family: 'Asap';
    font-weight: 600;
    line-height: 125%;
}
.location-map--ico {
    width: 22px;
    /* height: 22px; */
    height: auto;
    margin-right: 4px;
    
    flex: none;
    order: 0;
    flex-grow: 0;
    place-items: center;
}