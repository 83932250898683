.cardsGridTilesGridHdr h2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    gap: 4px;
    font-family: 'Asap';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 125%;
    flex: none;
    order: 0;

    padding: 44px 0px 24px 0px;
}
.cardsGridTilesGridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 0;
  position: relative;

  padding: 0px 180px;
}
.cardsGridTilesGridPaginationContainer {
  padding: 14px 180px 64px 180px;
}

.cardsGridTilesCard {
  background: #F9FBFE;
  border: 1px solid #ddd;
  border-radius: 14px;
  padding: 20px;
  box-shadow: 0px 4px 24px rgba(12, 12, 12, 0.12);
  transition: transform 0.3s ease-in-out;
  position: relative;

  /* border: 1px solid red; */
}
.cardsGridTilesCard:hover {
  transform: translateY(-5px);
} 
.cardsGridTilesBookCount {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Wyśrodkuj zawartość w poziomie */
  gap: 6px;
  font-family: 'Asap';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 125%;

  color: #40466a;
  fill: #40466a;
}
.cardsGridTilesImage {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  position: relative;
  /* border: 1px solid yellow; */
  text-align: center;
}

/* name/address/description/temporary_status */
.cardsGridTiles-grid--item h3 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  gap: 4px;
  font-family: 'Asap';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 125%;
  flex: none;
  order: 0;
}
.cardsGridTiles-grid--item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  gap: 4px;
  font-family: 'Asap';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 125%;
  flex: none;
  order: 0;
}

/* BookContainer */
.book-container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  align-items: flex-start;
  width: 100%;
  padding: 8px 0px 0px 0px;
  /* border: 1px solid blue; */
}
.book-container-image {
  margin: 14px 24px 14px 24px;
  cursor: pointer;
  margin: 0 auto;
}
.book-container-image-img {
  border-radius: 14px;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Animacja przejścia */
  box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.3); /* Początkowy cień */
  margin: 24px 0px 14px 0px;
}
.book-container-image-img:hover {
  transform: scale(1.05); /* Lekkie powiększenie */
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5); /* Większy cień dla efektu uwypuklenia */
}

.book-container-info {
  text-align: left;
  word-wrap: break-word;
  flex: 1; 

  padding: 8px 24px 0px 24px;
}
.book-container-info-title:hover {
  color: #ABC4F0;
}
.book-container-info h1 {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  color: #000000;
}

.book-container-info-hdr {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 140%;
  color: #000000;
}
.book-container-info-hdr-fav {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 140%;
  color: #ABC4F0;
}

.book-container-info-txt {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 140%;
  color: #40466a;
}
.book-container-info-txt-fav {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 140%;
  color: #ABC4F0;
}

.book-container-info-txt-btn:hover {
  color: #ABC4F0;
}
.book-container-info-txt-btn-fav:hover {
  color: #40466a;
}

.book-container-info-txt-btn:hover .book-container-info-ico {
  fill: #ABC4F0; /* Zmiana koloru ikony */
}
.book-container-info-txt-btn-fav:hover .book-container-info-ico-fav {
  fill: #40466a; /* Zmiana koloru ikony */
}

.book-container-info-ico {
  font-size: 16px;
  margin:6px 4px 8px 0px;
  transition: color 0.3s ease; /* Dodaj przejście, jeśli chcesz płynną zmianę */
}
.book-container-info-ico-fav {
  font-size: 16px;
  margin: 6px 4px 8px 0px;
  transition: color 0.3s ease; /* Dodaj przejście, jeśli chcesz płynną zmianę */
}

.book-container-e-r-sec {
  position: absolute;
  right: 10px; /* Odległość od prawej krawędzi kontenera */
  top: 24px; /* Ustawienie 20px od góry kontenera */ /* 50%; Ustawienie na środku kontenera w pionie */
  /* transform: translateY(-50%); Usunięto, ponieważ nie jest już potrzebne */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin: 0px 2px 0px 0px;

  /* border: 1px solid blue; */
}
.book-container-e-r-ico {
  color:#40466a;
  fill: #40466a; /* Zmiana koloru ikony */
  cursor: pointer;
}
.book-container-e-r-ico:hover {
  color:#ABC4F0;
  fill: #ABC4F0; /* Zmiana koloru ikony */
}

/* BookAmazonAffiliate */
.book-container-amazon-affiliate {
  /* margin: 14px 24px 14px 24px; */
  cursor: pointer;
  padding-bottom: 12px;
}
.book-container-amazon-affiliate-icon {
  /* width: 100px; */
  /* height: 150px; */
  border-radius: 14px;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Animacja przejścia */
  box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.3); /* Początkowy cień */
  padding:8px;
}
.book-container-amazon-affiliate-icon:hover {
  transform: scale(1.05); /* Lekkie powiększenie */
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5); /* Większy cień dla efektu uwypuklenia */
} 

/* Pokaż szczegóły */
.cgt--btn-location {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 14px;
  gap: 12px;

  /* width: 282px; */
  height: 32px;

  background: #40466a;
  border-radius: 14px;
  border: 1px solid #40466a;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  color: #FBFBFF;

  margin-top: 14px;
  margin-bottom: 14px;

  /* Dodajemy przejście dla płynnej animacji */
  transition: box-shadow 0.3s, background-color 0.3s, color 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;

  cursor: pointer;
}
.cgt--btn-location::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: #FBFBFF;
  transition: transform 0.3s ease-in-out;
  z-index: -1;
}
button.cgt--btn-location:hover::before {
  transform: translateX(100%);
}
button.cgt--btn-location:hover {
  color: #000732; /* Czcionka zmienia kolor na ciemny */
}
.cgt--btn-location-t {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 125%;
  flex: none;
  order: 0;
  display: flex;
  place-items: center;
}
.cgt--ico {
  width: 16px;
  /* height: 22px; */
  height: auto;
  margin-right: 6px;
  
  flex: none;
  order: 0;
  flex-grow: 0;
}

/* Edytuj pozycję */
/* Pokaż pozycję */
/* Usuń pozycję */
/* RenderLocationCard */
.edit-view-btn-box-sec {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin: 1px 0 0 0;
  position: absolute;
  right: 14px;
  top: 14px;
  perspective: 1000px;
  background: #f8f8f8;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  overflow: hidden;
  z-index: 1;
  padding: 8px;
}
.edit-view-btn-box-sec::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 10px;
  box-shadow: 5px 5px 13px rgba(0, 0, 0, 0.3);
  z-index: -1;
}
.edit-view-btn-box-sec-ico {
  color: #40466a;
  fill: #40466a;
  cursor: pointer;
}
.edit-view-btn-box-sec-ico:hover {
  color: #ABC4F0;
  fill: #ABC4F0;
}

.cgt--wrp {
  background: #F9FBFE;
  box-shadow: 0px 4px 24px rgba(12, 12, 12, 0.12);
  border-radius: 28px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  /* display: flex; */
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;

  /* border: 1px solid red;  */
}
.cgt--wrp-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  gap: 10px;
  margin-top: 32px;

  height: 36px;

  /* Black */
  background: #40466a;
  border-radius: 14px;

  /* Inside auto layout */
  flex: none;
  order: 2;
  align-self: center;
  flex-grow: 0;

  font-family: 'Asap';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */

  /* White */
  color: #FBFBFF;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;

  border: 2px solid #40466a;
  /* width: 99%; */
  width: 100%;

  /* Dodajemy przejście dla płynnej animacji */
  transition: box-shadow 0.3s, background-color 0.3s, color 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.cgt--wrp-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: #FBFBFF;
  transition: transform 0.3s ease-in-out;
  z-index: -1;
}
button.cgt--wrp-btn:hover::before {
  transform: translateX(100%);
}
button.cgt--wrp-btn:hover {
  color: #000732; /* Czcionka zmienia kolor na ciemny */
}
.cgt--wrp-pagination {
  margin-top: 32px;
}

/* OnTablet */
@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .cardsGridTilesCard .additionalInfo,
  .cardsGridTilesCard .description {
    display: none;
  }
  .cardsGridTilesGridContainer {  
      padding: 0px 24px;
  }
  .cardsGridTilesGridPaginationContainer {
    padding: 8px 24px 64px 24px;
  }
  .book-container-e-r-sec {
    margin: 0px 14px 0px 0px;
  }
}
/* OnMobile */
@media only screen and (min-width: 0px) and (max-width: 767px) {
  .cardsGridTilesGridContainer {  
      padding: 0px 14px;
  }
  .cardsGridTilesGridPaginationContainer {
    padding: 0px 14px 64px 14px;
  }
  .book-container-e-r-sec {
    margin: 0px 8px 0px 0px;
  }
}

.spn-cnt {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: auto; */
  /* margin-right: auto; */
}

.cardsGridTilesGrid--wrp {
  background: #F9FBFE;
  box-shadow: 0px 4px 24px rgba(12, 12, 12, 0.12);
  border-radius: 28px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  /* max-width: 1080px; */

  /* padding-right: 42px;  */

  /* display: flex; */
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;

  /* border: 1px solid red;  */
}
