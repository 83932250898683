.bug-report--ico {
    flex: none;
    order: 0;
    flex-grow: 0;
    fill: #000732;
    margin-right: 10px;
}
.bug-report--sec {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    word-wrap: break-word;
}
.bug-report--sec-hdr {
    font-family: 'Asap';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 150%;
    
    color: #000732;
    
    flex: none;
    order: 0;
    flex-grow: 0;

    word-wrap: break-word;
}
.bug-report--sec-form {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 12px;

    overflow-y: auto;
    white-space: nowrap;
    width: 100%;
    overflow-y: hidden;
    padding: 4px;

    word-wrap: break-word;
}
.bug-report--sec-form-btn {
    font-family: 'Asap'!important;;
    font-style: normal!important;;
    font-weight: 500!important;;
    font-size: 16px!important;;
    line-height: 150%;
    
    color: #40466a!important;
}
.bug-report--wrp {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Dwie równe kolumny */

    flex-wrap: wrap;
    gap: 12px;
    /* width: 100%; */
    word-wrap: break-word;
}
.bug-report--wrp > div {
    flex: 1; /* Oznacza, że każdy div powinien zająć równą przestrzeń */
}
.bug-report--sec-txt {    
    font-family: 'Asap';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    
    color: #40466a;
    
    flex: none;
    order: 0;
    flex-grow: 0;

    word-wrap: break-word;

    padding: 0px 14px 0px 14px;
}

/* OnTablet */
@media only screen and (min-width: 768px) and (max-width: 1200px) {

}
/* OnMobile */
@media only screen and (min-width: 0px) and (max-width: 767px) {
    .bug-report--sec-hdr {
        font-size: 15px;
    }
    .bug-report--wrp {
        grid-template-columns: 1fr; /* Jedna kolumna, czyli wiersze */
    }
}
