.public-transit--sec {
    /* display: flex; */
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 12px;

    overflow-y: auto;
    /* white-space: nowrap; */
    width: 100%;
    overflow-y: hidden;
    padding: 1px;

    text-align: center;
}
.public-transit--sec-wrp {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 3px 0px;
    gap: 8px;

    /* width: 282px; */
    /* height: 32px; */
    height: auto;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    /* height: 24px; */
    height: auto;

    font-family: 'Asap';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    
    color: #000732;
    
    flex: none;
    order: 0;
    flex-grow: 0;

    word-wrap: break-word;
}
.public-transit--sec-wrp h2 {
    font-family: 'Asap';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 125%;
    color: #000732;
}
.public-transit--ico {
    width: 22px;
    /* height: 22px; */
    height: auto;
    margin-right: 4px;
    
    flex: none;
    order: 0;
    flex-grow: 0;
}
