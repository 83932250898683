.s-s-b--con {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0px;
}
.s-s-b--con-select {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* width: 179px; */
    /* height: 24px; */

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}
.s-s-b--con-select select {
    color: var(--black, #000732);
    font-size: 16px;
    font-family: 'Asap';
    line-height: 150%;
    font-weight: 600;

    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    /* background-image: url('../../../assets/icons/sort-arrows.svg'); */
    background-image: url('/assets/icons/sort-arrows.svg');
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 5px;
    border: 0px solid #F8F4EE;
    border-radius: 2px;
    /* margin-right: 2rem; */
    /* padding: 1rem; */
    padding-right: 1.2rem;
}
.s-s-b--con-select-ico {
    width: 9px;
    height: 15px;
}
