.brcbs--sec {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 180px 0px 180px;
    gap: 10px;
    flex: none;
    order: 1;
    flex-grow: 0;
}
.brcbs--con {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 10px;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    
    /* border: 1px solid red; */
}
.brcbs--l {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 32px;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.brcbs--l ol {
    display: flex;
    list-style-type: none; /* To usunie numerację */
    padding: 0; /* Usuwa wewnętrzny odstęp (padding) */
    margin: 0; /* Usuwa zewnętrzny odstęp (margin) */
    gap: 10px; /* Ustawia odstępy między elementami */
    align-items: center;
}
.brcbs--l a {
    text-decoration: none;
    color: var(--Black, #000732);
    position: relative; /* Dodajemy to, aby móc pozycjonować pseudo-element */
    cursor: pointer; /* Dodajemy wskaźnik klikalności */
}
/* Tu dodajemy pseudo-element do każdego linku */
.brcbs--l a:after {
    content: '';
    position: absolute;
    left: 0;
    /* bottom: 0; */
    bottom: -5px; /* Odstęp pomiędzy tekstem a podkreśleniem */
    width: 0;
    height: 2px;
    background: var(--Black, #000732);
    transition: width 0.25s ease-out;
}
/* Animacja podkreślenia dla hover */
.brcbs--l a:hover:after {
    width: 100%;
}
.brcbs--r {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 12px;
    flex: none;
    order: 1;
    flex-grow: 0;
}
.brcbs--t {
    color: var(--Black, #000732);
    font-family: 'Asap';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 145%; /* 20.3px */
    position: relative; /* Ustawienie pozycji względnej dla rodzica, aby pseudo-element był względem niego pozycjonowany */
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-top: -2px;
    text-decoration: none; /* Wyłączenie domyślnego podkreślenia */
    cursor: pointer; /* Pokazuje wskaźnik, że można klikać */
}
.brcbs--t:after {
    content: "";
    position: absolute; /* Absolutne pozycjonowanie względem rodzica */
    left: 0;
     /* bottom: 0; */ /* Pozycjonowanie na dole tekstu */
    bottom: -5px; /* Odstęp pomiędzy tekstem a podkreśleniem */
    width: 0; /* Zaczyna z szerokością 0 */
    height: 2px; /* Wysokość podkreślenia */
    background: #000732; /* Kolor podkreślenia */
    transition: width 0.25s ease-out; /* Animacja szerokości */
}
.brcbs--t:hover:after {
    width: 100%; /* Pełna szerokość na hover */
}
.brcbs--ico {
    flex: none;
    order: 0;
    flex-grow: 0;
    fill: #000732;
    margin-left: 10px;

    transition: transform 0.3s ease, fill 0.3s ease; /* Dodaje płynną animację dla transformacji i koloru */
}
.brcbs--ico:hover{
    animation: pulse 1s infinite;
    /* animation: bounce 0.5s infinite alternate; */
    /* filter: brightness(0) saturate(100%) hue-rotate(-10deg) contrast(5); */
}
@keyframes pulse {
    0% {
      transform: scale(1) rotate(0deg);
      filter: brightness(100%);
    }
    50% {
      transform: scale(1.1) rotate(5deg);
      filter: brightness(130%);
    }
    100% {
      transform: scale(1) rotate(0deg);
      filter: brightness(100%);
    }
}
@keyframes pulse {
    0% {
      transform: scale(1) rotate(0deg);
      filter: brightness(100%);
    }
    50% {
      transform: scale(1.1) rotate(5deg);
      filter: brightness(130%);
    }
    100% {
      transform: scale(1) rotate(0deg);
      filter: brightness(100%);
    }
}
.brcbs--btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px 12px;
    gap: 10px;
    background: #000732;
    border-radius: 14px;

    flex: none;
    order: 0;
    flex-grow: 0;
}
.brcbs--btn-li {
    color: var(--Black, #000732);
    font-family: 'Asap';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 145%; /* 20.3px */
    flex: none;
    order: 1;
    flex-grow: 0;
}
.brcbs--btn-li, .brcbs--active {
    max-width: 500px!important; /* Maksymalna szerokość dla tekstu, można dostosować */
    /*overflow: hidden;*/ /* Ukrywa nadmiar tekstu */
    white-space: nowrap; /* Zapobiega łamaniu tekstu na nowe linie */
    text-overflow: ellipsis; /* Dodaje '...' na końcu skróconego tekstu */
}
/* OnTablet */
@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .brcbs--sec {
        padding: 32px 40px;
    }
    .brcbs--btn-li, .brcbs--active {
        max-width: 400px!important; /* Maksymalna szerokość dla tekstu, można dostosować */
    }
}
/* OnMobile */
@media only screen and (min-width: 0px) and (max-width: 767px) {
    .brcbs--sec {
        padding: 32px 4px;
    }
    .brcbs--con {
        margin-left: 20px;
        margin-right: 20px;
    }
    .brcbs--btn-li, .brcbs--active {
        display: none;
    }
}