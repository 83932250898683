.cdd--sec {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 34px 180px 34px 180px;
    gap: 24px;

    flex: none;
    order: 0;
    flex-grow: 0;

    margin-bottom: 40px;

    /* border: 4px solid red; */
}
.cdd--sec-l {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 64px;
    /* gap: 28px; */
    isolation: isolate;

    width: 70%;
    /* height: 4389px; */

    flex: none;
    order: 0;
    flex-grow: 1;

    /* position: relative; */
}

/* ResizableImage */
.cdd--sec-l-i {
    /* position: absolute; */
    position: relative!important;
    /* width: 91px; */
    /* height: 43px; */
    /* left: 635px; */
    width: 100%; 
    top: 0px;

    /* background: rgba(255, 255, 255, 0.6); */
    /* border-radius: 0px 14px; */
    border-radius: 14px 14px;

    flex: none;
    order: 0;
    flex-grow: 0;

    z-index: 0;

    cursor: pointer;

    /* border: 1px solid red; */
}

/* BookStickyInfo */
.cdd--sec-s-i,
.cdd--sec-s-i-m {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 0px;
    padding-bottom: 32px;
    gap: 12px;

    /* width: 330px; */
    /* height: 483px; */
    height: auto;

    background: #FBFBFF;
    /* Hover-Card */
    border: 1px solid #F0F4FA;
    box-shadow: 0px 4px 48px rgba(12, 12, 12, 0.08);
    border-radius: 14px;

    flex: none;
    order: 0;
    /* align-self: stretch; */
    flex-grow: 0;

    min-width: 260px;
}
.cdd--sec-s-i {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 4px;
}
.cdd--sec-s-i-wrp {
    display: flex;
    justify-content: space-between;
    align-items: space-between;
    width: 100%;
}
.cdd--sec-s-i-src {
    width: 158px;
    /* height: 46px; */
    height: auto;
    left: calc(100% - 158px);
    top: 0px;
    /* background: rgba(202, 202, 202, 0.6); */
    /* background: rgba(0, 0, 0, 0.6); */
    background: #ABC4F0;
    /* background: rgba(255, 255, 255, 0.6); */
    
    border-radius: 0px 14px;
    padding-top: 3px;

    flex: none;
    order: 0;
    flex-grow: 0;

    z-index: 10;

    text-align: center;
    /* align-self: flex-end; */
}
.cdd--sec-s-i-src-img {
    width: 132px;
    height: 39px;
    left: 9px;
    top: 2px;
}
.cdd--sec-s-i-t {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 24px;
    gap: 12px;

    /* width: 330px; */
    /* height: 287px; */

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.cdd--sec-s-i-t-inr {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;

    /* width: 282px; */
    /* height: 132px; */
    /* height: 318px!important; */
    height: auto;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.cdd--sec-s-i-t-dt {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* padding: 0px 24px; */
    gap: 6px;

    /* width: 282px; */
    /* height: 60px; */
    height: auto;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.cdd--sec-s-i-dt-h {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    font-family: 'Asap';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    
    color: #000732;

    width: 100%;
}
.cdd--sec-s-i-dt-h h3 {
    /* width: 101px; */
    /* height: 22px; */
    height: auto;
    
    /* Mobile-Head/H3 */
    font-family: 'Asap';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    
    color: #000732;
    
    flex: none;
    order: 0;
    flex-grow: 0;
}
.cdd--sec-s-i-dt-t {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 3px 0px;
    gap: 24px;
    /* width: 282px; */
    /* height: 32px; */
    height: auto;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.cdd--sec-d {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 12px;
    
    /* width: 113px; */
    /* height: 14px; */
    height: auto;
    
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.cdd--sec-d-i {
    width: 22px;
    /* height: 22px; */
    height: auto;
    
    flex: none;
    order: 0;
    flex-grow: 0;
}
.cdd--sec-d-d {
    width: 260px;
    /* height: 24px; */
    height: auto;
    
    font-family: 'Asap';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */
    
    color: #000732;
    
    flex: none;
    order: 0;
    flex-grow: 0;

    word-wrap: break-word;
}
.cdd--sec-t {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 12px;

    /* width: 76px; */
    height: 26px;

    flex: none;
    order: 0;
    flex-grow: 0;
}
.cdd--sec-t-i {
    width: 26px;
    height: 26px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}
.cdd--sec-t-t {
    /* width: 38px; */
    height: 24px;

    font-family: 'Asap';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;

    color: #000732;

    flex: none;
    order: 0;
    flex-grow: 0;
}
.cdd--sec-s-i-rec {
    /* width: 282px; */
    width: 100%;
    height: 3px;

    background: #F0F4FA;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.cdd--sec-s-i-t-btn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;

    /* width: 282px; */
    /* height: 128px; */
    height: auto;
    
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
/* Dodaj do ulubionych */
.cdd--sec-s-i-t-btn-add-fav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 11px 10px;
    gap: 12px;

    /* width: 282px; */
    height: 40px;

    background: #40466a;
    border-radius: 14px;
    border: 1px solid #40466a;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    color: #FBFBFF;
}
.cdd--sec-s-i-t-btn-add-fav:hover {
    background: #ABC4F0;
    border-radius: 14px;
    border: 1px solid #ABC4F0;
    color: #000732;
}
.cdd--sec-s-i-t-btn-add-fav-t {
    /* Dodaj do ulubionych */

    /* width: 129px; */
    height: 18px;

    /* Body/Cards */
    font-family: 'Asap';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 125%;

    /* White */
    /* color: #FBFBFF; */


    /* Inside auto layout */
    flex: none;
    order: 0;
}
/* Usuń z ulubionych */
.cdd--sec-s-i-t-btn-remove-fav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 11px 10px;
    gap: 12px;

    /* width: 282px; */
    height: 40px;

    background: #ABC4F0;
    border-radius: 14px;
    border: 1px solid #ABC4F0;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    color: #000732;
}
.cdd--sec-s-i-t-btn-remove-fav:hover {
    background: #40466a;
    border-radius: 14px;
    border: 1px solid #40466a;
    color: #FBFBFF;
}
.cdd--sec-s-i-t-btn-remove-fav-t {
    /* Usuń z ulubionych */

    /* width: 129px; */
    height: 18px;

    /* Body/Cards */
    font-family: 'Asap';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 125%;

    /* White */
    /* color: #FBFBFF; */


    /* Inside auto layout */
    flex: none;
    order: 0;
}
.cdd--sec-s-i-t-btn-cal {
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 11px 10px;
    gap: 12px;

    /* width: 282px; */
    height: 40px;

    border: 2px solid #40466a;
    border-radius: 14px;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.cdd--sec-s-i-t-btn-ext-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;

    /* width: 282px; */
    height: auto;

    font-family: 'Asap';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;

    color: #000732;

    flex: none;
    order: 0;
    flex-grow: 0;
}
.cdd--sec-s-i-t-btn-cal:hover {
    background: #ABC4F0;
    border: 2px solid #ABC4F0;
    border-radius: 14px;
    color: #000732;
}
.cdd--sec-s-i-t-btn-cal-t {
    /* Dodaj do kalendarza */

    /* width: 129px; */
    height: 18px;

    /* Body/Cards */
    font-family: 'Asap';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 125%;
    /* identical to box height, or 18px */

    /* Primary - Blue */
    color: #40466a;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}
.cdd--sec-s-i-t-btn-sm {
    /* Social Media */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* width: 282px; */
    height: 24px;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.cdd--sec-s-i-t-btn-sm-fb {
    /* width: 24px;
    height: 24px; */

    border-radius: 6px;

    flex: none;
    order: 0;
    flex-grow: 0;

    cursor: pointer;
}
.cdd--sec-s-i-t-btn-sm-fbmw {
    /* width: 24px;
    height: 24px; */
    
    flex: none;
    order: 0;
    flex-grow: 0;

    cursor: pointer;
}
.cdd--sec-s-i-brk {
    /* width: 330px; */
    height: 3px;

    background: #F0F4FA;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.cdd--sec-s-i-b {
    /* 2 */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 24px;
    /* gap: 12px; */

    /* width: 330px; */
    /* height: 81px; */

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.cdd--sec-s-i-b-p {
    /* display: flex; */
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 32px;

    /* width: 282px; */
    /* height: 29px; */

    border-radius: 4px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    /* overflow-y: hidden; */
    /* overflow-y: auto; */
    /* white-space: nowrap; */
    /* width: 100%; */

    /* scrollbar-width: none; */
    /* scrollbar-width: thin; */
    /* scrollbar-color: transparent;  */
    margin-top: 10px;
}
.cdd--sec-s-i-b-p h2 {
    /* Desktop-Head/H2 */
    font-family: 'Asap';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    /* or 22px */

    /* Black */
    color: #000732;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}
.cdd--sec-s-i-b-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 10px;
    gap: 12px;

    /* width: 282px; */
    height: 40px;

    /* Black */
    background: #000732;
    border-radius: 14px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    border: 2px solid #000732;
}
.cdd--sec-s-i-b-btn:hover {
    background: #40466a;
    border: 1px solid #40466a;
}
.cdd--sec-s-i-b-btn-f {
    /* width: 24px;
    height: 24px; */

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}
.cdd--sec-s-i-b-btn-f-ico {
    left: 8.33%;
    right: 8.33%;
    top: 12.5%;
    bottom: 12.5%;
    /* White */
    /* border: 1.8px solid #FBFBFF; */
}
.cdd--sec-s-i-b-btn-t {
    /* Zamawiam bilet */
    /* width: 101px; */
    height: 18px;

    /* Body/Cards */
    font-family: 'Asap';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 125%;
    /* identical to box height, or 18px */

    /* White */
    color: #FBFBFF;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

/* EventImagesBelt */
.cdd--sec-l-i-a-i {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 12px;
    isolation: isolate;

    /* width: 726px; */
    /* height: 110px; */
    width: 100%; 

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    position: relative;
    margin-top: -46px;
}
.cdd--sec-l-i-a-i-sec {
    overflow-y: auto;
    white-space: nowrap;
    width: 100%;
    /* overflow-y: hidden; */

    scrollbar-width: none;
    /* scrollbar-width: thin; */
    scrollbar-color: transparent;
}
.cdd--sec-l-i-a-i-img {
    height: 110px;
    width: auto;
    flex: 1 0 0;
    border-radius: 14px;
    padding: 2px;
}
.cdd--sec-l-i-a-i-img:first-child {
    padding-left: 0px;
}
.cdd--sec-l-i-a-i-img:last-child {
    padding-right: 0px;
}
.cdd--sec-l-i-a-i-arrow-l {
    display: flex;
    width: 32px;
    padding: 6.154px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6.154px;
    top: 39px;
    border-radius: 0px 140px 140px 0px;
    background: var(--no-active, #7E8199);

    position: absolute;
}
.cdd--sec-l-i-a-i-arrow-r {
    display: flex;
    width: 32px;
    padding: 6.154px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6.154px;
    /* position: absolute; */
    right: 0px;
    top: 39px;
    border-radius: 140px 0px 0px 140px;
    background: var(--no-active, #7E8199);

    position: absolute;
}
.cdd--sec-l-i-a-i-arrow-l:hover,
.cdd--sec-l-i-a-i-arrow-r:hover {
    background: var(--black-hover, #40466A);
}
.cdd--sec-l-i-a-i-arrow-ico {
    width: 19.692px;
    height: 19.692px;
}

/* EventReviewImagesSlideShow */
/* EventReviewUserComment */
.cdd--sec-l-r-rp-ur-box-i {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 12px;
    isolation: isolate;

    /* width: 726px; */
    /* height: 110px; */
    width: 100%; 

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    position: relative;
    /* margin-top: -46px; */

    /* align-items: flex-start; */
    /* align-self: stretch; */
    z-index: 100;
}
.cdd--sec-l-r-rp-ur-box-i-sec {
    overflow-y: auto;
    white-space: nowrap;
    width: 100%;
    /* overflow-y: hidden; */

    scrollbar-width: none;
    /* scrollbar-width: thin; */
    scrollbar-color: transparent;
}
.cdd--sec-l-r-rp-ur-box-i-img {
    height: 110px;
    flex: 1 0 0;
    border-radius: 14px;
    padding: 2px;
}
.cdd--sec-l-r-rp-ur-box-i-img-overlay {
    height: 100%;
    flex: 1 0 0;
    border-radius: 14px;
    padding: 2px;
}
/* .cdd--sec-l-r-rp-ur-box-i-img:active {
    position: absolute;
    justify-content: center;
    align-items: center;
    display:flex;
    margin: 0 auto;
    transform:scale(2.5);
} */
.cdd--sec-l-r-rp-ur-box-i-img:first-child {
    padding-left: 0px;
}
.cdd--sec-l-r-rp-ur-box-i-img:last-child {
    padding-right: 0px;
}
.cdd--sec-l-r-rp-ur-box-i-arrow-l {
    display: flex;
    width: 32px;
    padding: 6.154px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6.154px;
    top: 39px;
    border-radius: 0px 140px 140px 0px;
    background: var(--no-active, #7E8199);

    position: absolute;
}
.cdd--sec-l-r-rp-ur-box-i-arrow-r {
    display: flex;
    width: 32px;
    padding: 6.154px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6.154px;
    /* position: absolute; */
    right: 0px;
    top: 39px;
    border-radius: 140px 0px 0px 140px;
    background: var(--no-active, #7E8199);

    position: absolute;
}
.cdd--sec-l-r-rp-ur-box-i-arrow-l:hover,
.cdd--sec-l-r-rp-ur-box-i-arrow-r:hover {
    background: var(--black-hover, #40466A);
}
.cdd--sec-l-r-rp-ur-box-i-arrow-ico {
    width: 19.692px;
    height: 19.692px;
}

/* AdditionalInfo */
.cdd--sec-l-o {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 64px;

    /* width: 726px;
    height: 308px; */
    width: 100%; 

    flex: none;
    order: 0;
    flex-grow: 0;

    margin-top: -34px;
}
.cdd--sec-l-o-o {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;

    /* width: 726px;
    height: 111px; */

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}
.cdd--sec-l-o-o-o {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;

    /* width: 301px;
    height: 29px; */
    width: 100%;

    flex: none;
    order: 0;
    flex-grow: 0;
}
.cdd--sec-l-o-o-o-h {
    /* width: 88px;
    height: 24px; */

    /* Body/Body1 */
    font-family: 'Asap';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */

    /* Black */
    color: #000732;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}
.cdd--sec-l-o-o-o-t h2 {
    /* width: 201px; */
    /* height: 29px; */
    /* width: 82%; */
    /* Desktop-Head/H2 */
    font-family: 'Asap';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    /* identical to box height, or 29px */

    /* Black */
    color: #000732;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

    overflow-wrap: break-word;
    word-wrap: break-word;
}

/* ShortDescription */
.cdd--sec-l-sd {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;

    /* width: 726px;
    height: 89px; */
    width: 100%;

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    z-index: 2;
}
.cdd--sec-l-sd-h h2 {
    /* width: 113px; */
    height: 29px;

    /* Desktop-Head/H2 */
    font-family: 'Asap';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    /* identical to box height, or 29px */

    /* Black */
    color: #000732;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}
.cdd--sec-l-sd-t p {
    /* width: 726px;
    height: 48px; */

    /* Body/Body1 */
    font-family: 'Asap';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */

    /* Black */
    color: #000732;


    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

/* BookDescription */
.cdd--sec-l-ed {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 14px;

    /* width: 726px;
    height: 165px; */
    width: 100%;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    z-index: 3;
}
.cdd--sec-l-ed-h h2 {
    /* width: 726px; */
    height: 29px;

    font-family: 'Asap';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    /* identical to box height, or 29px */

    /* Black */
    color: #000732;

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.cdd--sec-l-ed-h-t {
    /* Body/Body1 */
    font-family: 'Asap';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */

    color: #000732;

    flex: none;
    order: 0;
    /* align-self: stretch; */
    flex-grow: 0;
    align-self:flex-end;
}
.cdd--sec-l-ed-h-t-link {
    color: var(--bs-link-color);
    text-decoration: underline;
    cursor: pointer;
    /* font-size: small; */
    margin-top: 8px;
    margin-right: 10px;
    text-align: right;
}
.cdd--sec-l-ed-h-ts {
    /* width: 726px; */
    height: 16px;

    font-family: 'Asap';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */

    color: #40466A;

    flex: none;
    order: 0;
    flex-grow: 0;
}
.cdd--sec-l-ed-h-ts a {
    text-decoration: none;
}

/* EventReviews */
.cdd--sec-l-r {
    /* Opinie */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 32px;

    /* width: 726px; */
    /* height: 1355px; */
    width: 100%;

    /* Inside auto layout */
    flex: none;
    order: 5;
    flex-grow: 0;
    z-index: 5;
}
.cdd--sec-l-r-rp {
    /* Panel z komentarzami */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;

    /* width: 726px; */
    /* height: 1108px; */
    width: 100%;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}
.cdd--sec-l-r-rp-c {
    /* Contner */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0px;
    gap: 8px;

    /* width: 726px; */
    /* height: 72px; */


    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.cdd--sec-l-r-rp-c-i {
    /* Inner */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    align-items: center;
    padding: 0px;
    gap: 24px;

    /* width: 726px; */
    /* height: 40px; */

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.cdd--sec-l-r-rp-c-i-h h2 {
    /* Opinie */

    /* width: 71px; */
    /* height: 29px; */

    /* Desktop-Head/H2 */
    font-family: 'Asap';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    /* identical to box height, or 29px */

    /* Black */
    color: #000732;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}
.cdd--sec-l-r-rp-c-i-rs {
    /* Opinie */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 18px;
    gap: 24px;

    /* width: 293px; */
    /* height: 40px; */

    /* Black */
    border: 1px solid #000732;
    border-radius: 14px;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}
.cdd--sec-l-r-rp-c-i-rs h2 {
    color: var(--black, #000732);
    /* Desktop-Head/H2 */
    font-size: 24px;
    font-family: 'Asap';
    font-weight: 600;
    line-height: 120%;
    margin-bottom: -2px;
}
/* SortReviews */
.cdd--sec-l-r-rp-c-s {
    /* Sortowanie */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* width: 179px; */
    /* height: 24px; */

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}
.cdd--sec-l-r-rp-c-s select {
    color: var(--black, #000732);
    font-size: 16px;
    font-family: 'Asap';
    line-height: 150%;
    font-weight: 600;

    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    /* background-image: url('../../../assets/icons/sort-arrows.svg'); */
    background-image: url('/assets/icons/sort-arrows.svg');
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 5px;
    border: 0px solid #F8F4EE;
    border-radius: 2px;
    /* margin-right: 2rem; */
    /* padding: 1rem; */
    padding-right: 1.2rem;
}
.cdd--sec-l-r-rp-c-s-ico {
    width: 9px;
    height: 15px;
}

/* EventReviewUserComment */
.cdd--sec-l-r-rp-ur {
    /* Opinie-Użytkowników */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 32px;

    /* width: 726px; */
    /* height: 1024px; */

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
    width: 100%;
}
.cdd--sec-l-r-rp-ur-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;

    word-break: break-all;
}
.cdd--sec-l-r-rp-ur-box-h {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    margin-bottom: 14px;
}
.cdd--sec-l-r-rp-ur-box-h-pd {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
}
.cdd--sec-l-r-rp-ur-box-h-pd-pr{
    display: flex;
    align-items: center;
    gap: 14px;
}
.cdd--sec-l-r-rp-ur-box-h-pd-pr-ico {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    object-fit: cover;  /* Zapewnia, że obraz będzie dobrze wyglądał, nawet jeśli jego wymiary są inne niż kontenera */
}
.cdd--sec-l-r-rp-ur-box-h-pd-pr-t {
    color: var(--black, #000732);
    /* Body/M-Body1 */
    font-size: 16px;
    font-family: 'Asap';
    font-weight: 500;
    line-height: 150%;
}
.cdd--sec-l-r-rp-ur-box-h-pd-rv {
    display: flex;
    align-items: center;
    gap: 12px;
}
.cdd--sec-l-r-rp-ur-box-h-pd-rv-s {
    display: flex;
    align-items: flex-start;
    gap: 6px;
}
.cdd--sec-l-r-rp-ur-box-h-pd-p {
    color: var(--no-active, #7E8199);
    /* Body/M-Body2 */
    font-size: 14px;
    font-family: 'Asap';
    font-weight: 500;
    line-height: 145%;
}
.cdd--sec-l-r-rp-ur-box-h-pd-ico {
    width: 28.327px;
    height: 28px;
}
.cdd--sec-l-r-rp-ur-box-t {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    color: var(--black, #000732);
    /* Body/Body1 */
    font-size: 16px;
    font-family: 'Asap';
    line-height: 150%;
}
.cdd--sec-l-r-rp-ur-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    margin-top: 20px;
}
.cdd--sec-l-r-rp-ur-empty h2 {
    color: #000;
    /* Desktop/H2 */
    font-size: 24px;
    font-family: 'Asap';
    font-weight: 700;
    line-height: 28px;
}
.cdd--sec-l-r-rp-ur-empty-img {
    display: flex;
    width: 270px;
    height: 270px;
    padding: 23.76px 19.345px;
    justify-content: center;
    align-items: center;
}

/* LeaveReview */
.cdd--sec-l-r-lr {
    /* Opinie- napisać */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 0px;
    gap: 18px;

    /* width: 726px; */
    /* height: 215px; */

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
    width: 100%;
}
.cdd--sec-l-r-lr-h {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 18px;
    align-self: stretch;
}
.cdd--sec-l-r-lr-h h2 {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    color: var(--black, #000732);
    /* Desktop-Head/H2 */
    font-size: 24px;
    font-family: 'Asap';
    font-weight: 600;
    line-height: 120%;
}
.cdd--sec-l-r-lr-fc {
    display: flex;
    /* height: 110px; */
    padding: 8px 10px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 14px;
    border: 1px solid var(--black, #000732);

    color: var(--black, #000732);
    /* Body/Body1 */
    font-size: 16px;
    font-family: 'Asap';
    line-height: 150%;
}
.cdd--sec-l-r-lr-fc-d {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}
.cdd--sec-l-r-lr-fc-d-l {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    border-radius: 14px;
}
.cdd--sec-l-r-lr-fc-d-l-ico {
    width: 18px;
    height: 18px;
}
.cdd--sec-l-r-lr-fc-d-l-t {
    color: var(--black, #000732);
    text-align: right;
    /* Body/M-Body2 */
    font-size: 14px;
    font-family: 'Asap';
    font-weight: 500;
    line-height: 145%;
}
.cdd--sec-l-r-lr-fc-d-r{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
}
.cdd--sec-l-r-lr-fc-d-r-s {
    display: flex;
    align-items: center;
    gap: 8.17px;
}
.cdd--sec-l-r-lr-fc-d-r-s h3 {
    color: var(--black, #000732);
    font-size: 18px;
    font-family: 'Asap';
    font-weight: 600;
    line-height: 120%;
    margin-bottom: -2px;
    width: 50px;
    text-align: right;
}
.cdd--sec-l-r-lr-fc-d-r-btn {
    display: flex;
    padding: 11px 24px;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    border-radius: 14px;
    background: var(--primary-blue, #40466a)
}
.cdd--sec-l-r-lr-fc-d-r-btn:hover {
    background: var(--black, #000732);
    color: #000732!important;
}
.cdd--sec-l-r-lr-fc-d-r-btn-ico {
    /* width: 18.013px; */
    width: 19px;
    height: 18px;
}
.cdd--sec-l-r-lr-fc-d-r-btn-t {
    color: var(--white, #FBFBFF);
    /* Body/Cards */
    font-size: 14px;
    font-family: 'Asap';
    font-weight: 600;
    line-height: 125%;
}

/* BookCondition */
.cdd--sec-l-o-c {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 14px;
    
    width: 100%;
    /* width: 726px; */
    /* height: 536px; */

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}
.cdd--sec-l-o-c-sec {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 14px;
    
    width: 50%;
    /* width: 351px; */
    /* height: 536px; */
    
    border-radius: 14px;
    
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 1;
}
.cdd--sec-l-o-c-sec-h h2 {
    /* width: 127px; */
    height: 19px;

    font-family: 'Asap';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;

    color: #000732;

    flex: none;
    order: 0;
    flex-grow: 0;
}
.cdd--sec-l-o-c-sec-c {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    /* align-items: center; */
    padding: 0px;
    gap: 14px;

    /* width: 351px; */
    /* height: 483px; */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.cdd--sec-l-o-c-sec-c-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    
    /* width: 351px; */
    /* height: 45px; */
    
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.cdd--sec-l-o-c-sec-c-item-h {
    /* width: 351px; */
    height: 20px;

    /* Body/M-Body2 */
    font-family: 'Asap';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 145%;
    /* or 20px */

    /* Black */
    color: #000732;

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.cdd--sec-l-o-c-sec-c-item-c {
    /* width: 351px; */
    /* height: 21px; */

    /* Body/Body2 */
    font-family: 'Asap';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */

    /* Black */
    color: #000732;


    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.cdd--sec-l-o-c-sec-c-item-c a {
    text-decoration: none;
}
.cdd--sec-l-o-c-sec-c-item-c-fb {
    background-color: #40466a;
    /* width: 24px;
    height: 24px; */
    padding-top: 4px;

    border-radius: 6px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

/* Podobne niedaleko Ciebie */
.cdd--sec-snm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 180px 64px;
    gap: 24px;

    /* width: 1440px; */
    /* max-height: 1174px; */
    /* width: 100%; */

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

    margin-bottom: 24px;
}
.cdd--sec-snm-h h1 {
    /* width: 392px; */
    height: 64px;

    font-family: 'Asap';
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 120%;

    color: #000000;

    flex: none;
    order: 0;
    flex-grow: 0;
}

/* Tags */
.cdd--sec-l-t {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;

    /* width: 726px;
    height: 73px; */

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.cdd--sec-l-t-h h2 {
    /* width: 45px;
    height: 29px; */

    /* Desktop-Head/H2 */
    font-family: 'Asap';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    /* identical to box height, or 29px */
    
    /* Black */
    color: #000732;
    
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}
.cdd--sec-l-t-l {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;

    /* width: 726px;
    height: 32px; */

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    overflow-y: auto;
    white-space: nowrap;
    width: 100%;
    overflow-y: hidden;

    scrollbar-width: none;
    /* scrollbar-width: thin; */
    scrollbar-color: transparent;
}
.cdd--sec-l-t-t {
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 6px 12px;
    gap: 10px;

    /* width: 136px; */
    /* height: 32px; */

    /* background: #FBFBFF; */
    /* Black */
    /* border: 2px solid #000732;
    border-radius: 14px; */

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    text-align: center;

    flex-wrap: wrap;
}
.cdd--sec-l-t-t-t {
    /* width: 112px; */
    height: 24px;
    width: auto;
    padding: 0px 12px;

    /* Body/M-Body2 */
    font-family: 'Asap';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 145%;
    /* or 20px */

    /* Black */
    color: #000732;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

    border: 1px solid #000732;
    border-radius: 14px;

    background: var(--white, #FBFBFF);
    box-shadow: 0px 3px 6px rgba(0, 21, 93, 0.12), 0px 1px 3px rgba(0, 21, 93, 0.08);
}
.cdd--sec-l-t-t-t:hover {
    /* opacity: 0.5; */
    border: 1px solid #ABC4F0;
    color: #000732;
    background: #ABC4F0;
}

/* BookCondition */
.cdd--sec-l-c {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 14px;

    /* width: 726px;
    height: 494px; */

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.cdd--sec-l-c-h h2 {
    /* width: 726px;
    height: 29px; */

    font-family: 'Asap';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    
    color: #000732;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.cdd--sec-l-c-l {
    font-family: 'Asap';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    /* or 21px */

    color: #000732;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

/* getCategoryList */
.cdd--sec-cat-l {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px;

    /* overflow-y: auto; */
    overflow-y: hidden;

    scrollbar-width: none;
    /* scrollbar-width: thin; */
    scrollbar-color: transparent;

    max-width: 317px;
    margin-top: 8px;
    margin-top: -5px;
    margin-bottom: 15px;
}
.cdd--sec-cat-i {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 12px;
    gap: 12px;
    
    /* height: 24px; */

    background: #FBFBFF;
    border: 2px solid #000732;
    border-radius: 14px;
    border: 0.5px solid #000732;

    flex: none;
    order: 0;
    flex-grow: 0;

    font-family: 'Asap';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;

    color: #000732;

    flex: none;
    order: 0;
    flex-grow: 0;

    margin-bottom: 4px;
}
.cdd--sec-cat-i:hover {
    border: 0.5px solid #ABC4F0;
    background: #ABC4F0;
    color: #000732;
}

/* MobileMenu */
.cdd--mob-menu {
    display: block;
    width: 100%;
}
.cdd--mob-m {
    display: flex;
    align-items: center;
    align-self: stretch;
    border-radius: 14px;
    border: 1px solid var(--black, #000732);
    background: var(--white, #FBFBFF);
}
.cdd--mob-m-t {
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 14px;
    cursor: pointer;
}
.cdd--mob-m-t-atv-l {
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
    background: var(--black, #000732);
    color: var(--white, #FBFBFF);

    /* Body/M-Body2 */
    font-size: 14px;
    font-family: 'Asap';
    font-weight: 500;
    line-height: 145%;

    border-radius: 13px 0 0 13px;
}
.cdd--mob-m-t-atv-m {
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
    background: var(--black, #000732);
    color: var(--white, #FBFBFF);

    /* Body/M-Body2 */
    font-size: 14px;
    font-family: 'Asap';
    font-weight: 500;
    line-height: 145%;

    border-radius: 0px 0 0 0px;
}
.cdd--mob-m-t-atv-r {
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
    background: var(--black, #000732);
    color: var(--white, #FBFBFF);

    /* Body/M-Body2 */
    font-size: 14px;
    font-family: 'Asap';
    font-weight: 500;
    line-height: 145%;

    border-radius: 0 13px 13px 0;
}
.cdd--mob-m-t:hover {
    font-size: 14px;
    font-family: 'Asap';
    font-weight: 500;
    line-height: 145%;
}

/* BookStickyInfoMenu */
.cdd--sticky-menu {
    display: block;
    width: 100%;
    margin-top: 14px;
}
.cdd--sticky-menu-sec {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 8px;
    gap: 4px;
}
.cdd--sticky-m {
    display: flex;
    align-items: center;
    align-self: stretch;
    border-radius: 14px;
    border: 1px solid var(--black, #000732);
    background: var(--white, #FBFBFF);
}
.cdd--sticky-m-t {
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 14px;
    cursor: pointer;
}
.cdd--sticky-m-t-atv-l {
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
    background: var(--black, #000732);
    color: var(--white, #FBFBFF);

    /* Body/M-Body2 */
    font-size: 14px;
    font-family: 'Asap';
    font-weight: 500;
    line-height: 145%;

    border-radius: 13px 0 0 13px;
}
.cdd--sticky-m-t-atv-m {
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
    background: var(--black, #000732);
    color: var(--white, #FBFBFF);

    /* Body/M-Body2 */
    font-size: 14px;
    font-family: 'Asap';
    font-weight: 500;
    line-height: 145%;

    border-radius: 0px 0 0 0px;
}
.cdd--sticky-m-t-atv-r {
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
    background: var(--black, #000732);
    color: var(--white, #FBFBFF);

    /* Body/M-Body2 */
    font-size: 14px;
    font-family: 'Asap';
    font-weight: 500;
    line-height: 145%;

    border-radius: 0 13px 13px 0;
}
.cdd--sticky-m-t:hover {
    font-size: 14px;
    font-family: 'Asap';
    font-weight: 500;
    line-height: 145%;
}

.cdd--btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 0px;

    /* Primary - Blue */
    background: #40466a;
    border-radius: 14px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
    
    font-family: 'Asap';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    /* White */
    color: #FBFBFF;
    margin-top: 16px;

    border: 2px solid #40466a;
}
.cdd--page-not-exist {
    font-family: 'Asap';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #000732;
}
.cdd--spn-cnt a:link { text-decoration: none; }
.cdd--spn-cnt a:visited { text-decoration: none; }
.cdd--spn-cnt a:hover { 
    text-decoration: none; 
    border: 2px solid #ABC4F0;
    background: #ABC4F0;
    color: #000732;
}
.cdd--spn-cnt a:active { text-decoration: none; }
.cdd--ico {
    width: 28px;
    height: auto;
    flex: none;
    order: 0;
    flex-grow: 0;
    color: #40466a;
    margin: 0px 24px;
    padding-top: 10px;
    cursor: pointer;
}
.cdd--ico:hover {
    animation: pulse 1.5s infinite;
    color: #ABC4F0;
}

/* Edytuj pozycję */
/* Pokaż pozycję */
/* Usuń pozycję */
/* BookEditRemoveBtn */
.edit-view-btn-box-sec {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    margin: 1px 0 0 0;
    position: absolute;
    right: 10px;
    top: 10px;
    perspective: 1000px;
    background: #f8f8f8;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    overflow: hidden;
    z-index: 1;
    padding: 8px;
}
.edit-view-btn-box-sec::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 10px;
    box-shadow: 5px 5px 13px rgba(0, 0, 0, 0.3);
    z-index: -1;
}
.edit-view-btn-box-sec-ico {
    color: #40466a;
    fill: #40466a;
    cursor: pointer;
}
.edit-view-btn-box-sec-ico:hover {
    color: #ABC4F0;
    fill: #ABC4F0;
}

/* BookAmazonAffiliate */
.book-container-amazon-affiliate {
    /* margin: 14px 24px 14px 24px; */
    display: flex;
    cursor: pointer;
    justify-content:flex-end;
    margin-top: 8px;
    position: relative;
    /* position: absolute;
    right: 10px;
    top: 100px; */
}
.book-container-amazon-affiliate-icon {
    /* width: 100px; */
    /* height: 150px; */
    border-radius: 14px;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Animacja przejścia */
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.3); /* Początkowy cień */
    padding:8px;
}
.book-container-amazon-affiliate-icon:hover {
    transform: scale(1.05); /* Lekkie powiększenie */
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5); /* Większy cień dla efektu uwypuklenia */
}

/* OnDesktop */
@media only screen and (min-width: 1201px) {
    .cdd--sec-s-i-m {
        display: none;
    }
    .cdd--sec-s-i {
        width: 30%;
    }
    .cdd--mob-menu {
        display: none;
    }
}
/* OnTablet */
@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .cdd--sec {
        padding: 6px 40px;
    }
    .cdd--sec-snm{
        padding: 6px 40px;
    }
    .cdd--sec-s-i {
        /* display: none; */
        width: 30%;
    }
    .cdd--sec-s-i-m {
        display: none;
    }
    .cdd--mob-menu {
        display: none;
    }
}
/* OnMobile */
@media only screen and (min-width: 0px) and (max-width: 767px) {
    .cdd--sec {
        padding: 6px 24px;
    }
    .cdd--sec-l {
        gap: 48px;
    }
    .cdd--sec-l-i {    
        text-align: center;
    }
    .cdd--sec-snm{
        padding: 34px 24px;
        padding: 0px 24px 34px 24px;
    }
    .cdd--sec-s-i {
        display: none;
    }
    .cdd--sec-l-o-c {
        flex-direction: column;
    }
    .cdd--sec-l-o-c-sec {        
        width: 100%;
    }
    .cdd--sec-s-i-m {
        width: 100%;
        justify-content: center;
    }
    .cdd--sec-l-o-c-sec:first-child(1) {
        margin-top: 50px;
    }
    .cdd--sec-l-o-c-sec-h h2 {
        height: 10px;
    }
    .cdd--sec-l-o-c-sec:nth-child(2) {
        margin-top:24px;
    }
    .cdd--sec-l-r-rp-c-i {
        flex-direction: column;
        align-items:baseline;
    }
    .cdd--sec-snm-h h1 {
        height: 44px;
        font-size: 24px;
    }
    .cdd--mob-menu {
        margin-top: -34px;
    }
    .cdd--sec-cat-l {
        margin-bottom: -20px;
    }
    .cdd--sec-l-r-lr-fc-d-r{
        flex-direction: column;
        gap: 8px;
        align-items: flex-end;
    }
    .cdd--sec-l-r-rp-ur-box-h-pd {
        flex-direction: column;
        gap: 4px;
    }
    .cdd--sec-l-r-rp-ur-box-h-pd:nth-child(2) {
        flex-direction: row;
        gap: 4px;
    }
    .cdd--sec-l-r-rp-c-i-rs { 
        align-self: flex-end;
    }
    .book-container--e-r-sec {
        position: relative;
        left: 0;
        margin: unset;
        top: -24px;
    }
}

.cdd--spn-cnt {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 340px;
}
.ril__toolbarItem {
    font-size: 100%;
}
.ril__captionContent {
    font-size: 80%!important;
}
/* .ReactModal__Overlay--before-close {
    pointer-events: none;
}
.ReactModal__Content .ReactModal__Content--after-open{
    pointer-events: none;
} */
