.cat-box-subcat-con {
    /* top: 50%; */
    /* left: 0; */
    /* Usuwamy pozycjonowanie absolutne i transformacje oraz box-shadow */
    /* transform: translateX(10px) translateY(-100%); */
    /* transform: translateX(20px); */
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    /* position: absolute; */
    position: relative; /*lub static, w zależności od potrzeb*/
    /* z-index: 100; */
    padding: 5px 0px; /* Dodane dla lepszego wyświetlania */
    background: var(--White, #FBFBFF);
    /* width: 330px;  */ /* Dodane, aby zabezpieczyć szerokość maksymalną */
    overflow-wrap: break-word; /* Umożliwia łamanie długich słów */
    word-wrap: break-word; /* Wcześniejsza wersja overflow-wrap dla kompatybilności */
    white-space: normal; /* Domyślne zachowanie zawijania tekstu */
    padding-left: 14px; /* Dodajemy wcięcie lewe */
    /*border-left: 2px solid #ddd;*/ /* Dodajemy lewą granicę, by zaznaczyć to jako podkategorię */
    /*overflow-x: auto;*/ /* Pokazuje pasek przewijania, jeśli tekst nadal wychodzi poza */
}
.cat-box-subcat-con-btn {
    display: flex;
    padding: 8px 12px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    /* border-radius: 14px; */
    color: var(--Black, #000732);
    cursor: pointer;
}
.cat-box-subcat-con-btn:hover {
    background: #000732;
    color: var(--White, #F9FBFE);
}
.cat-box-subcat-con-btn-selected {
    background-color: #00A912; /* przykładowy kolor dla wybranej kategorii */
    color: #F9FBFE; /* przykładowy kolor tekstu dla wybranej kategorii */
    /* inne style jak potrzebujesz */
}
.cat-box-subcat-con-con-btn-i-no-rotate {
    transform: translateY(-50%) rotate(0deg); /* Obrót o 0 stopni */
    /* color: var(--Black, #000732); */
}
.cat-box-subcat-con-con-btn-i-rotate {
    transform: translateY(-50%) rotate(90deg); /* Obrót o 90 stopni */
    /* color: var(--White, #F9FBFE); */
}
.cat-box-subcat-con-con-btn-i-no-rotate, .cat-box-subcat-con-con-btn-i-rotate {
    /* position: absolute; */
    display: flex;
    margin-top:12px;
    align-items: center; /* Centrowanie w pionie */
    justify-content: center; /* Centrowanie w poziomie */
    right: 14px; /* Dostosuj, aby ustawić strzałkę */
    /*top: 50%;*/ /* Centruj w pionie */
    /* transform: translateY(-50%);*/ /* Centrowanie */
    transition: transform 0.3s ease; /* Dodano animację */
}
.cat-box-subcat-con-hd h3 {
    color: var(--Black, #000732);
    font-family: 'Asap';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 21.6px */
}
.cat-box-subcat-con-rect {
    height: 1px;
    align-self: stretch;
    border-radius: 22px;
    background: var(--Hover, rgba(40, 116, 218, 0.20));
}
.cat-box-subcat-con-no-subcat {
    display: flex;
    justify-content: center;
    color: var(--Black, #000732);
    font-family: 'Asap';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 21.6px */
    margin-top: 54px;
    margin-bottom: 30px;

    position: relative;

    width: fit-content;
    margin-left: auto;
    margin-right: auto;

    /* border: 1px solid red; */
}
.cat-box-subcat-con-no-subcat:after {
    content: "";
    position: absolute; /* Absolutne pozycjonowanie względem rodzica */
    left: 0;
     /* bottom: 0; */ /* Pozycjonowanie na dole tekstu */
    bottom: -5px; /* Odstęp pomiędzy tekstem a podkreśleniem */
    width: 0; /* Zaczyna z szerokością 0 */
    height: 2px; /* Wysokość podkreślenia */
    background: #000732; /* Kolor podkreślenia */
    transition: width 0.25s ease-out; /* Animacja szerokości */
}
.cat-box-subcat-con-no-subcat:hover:after {
    width: 100%; /* Pełna szerokość na hover */
}
.cat-box-subcat-switch-subcat-to-cat {
    /* Punkt transformacji na środku ikony */
    transform-origin: center;
    /* Przejście dla animacji transformacji i zmiany koloru */
    transition: transform 0.3s ease-in-out, color 0.3s ease;
}
.cat-box-subcat-switch-subcat-to-cat:hover {
    /* Efekt zmiany koloru */
    color: #40466a;
    
    /* Rozciąganie ikony proporcjonalnie we wszystkich kierunkach */
    /* transform: scale(1.5); */

    /* Rotacja o 360 stopni */
    /* transform: rotate(360deg); */
    
    /* Rotacja o 360 stopni i skalowanie */
    transform: rotate(360deg) scale(1.5);
}
.cat-box-subcat-hidden {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    animation: slideUp 10s ease forwards;
    max-width: 100%;
}
.cat-box-subcat-visible {
    display: block;
    max-height: 4000px;
    overflow: hidden;
    animation: slideDown 1s ease forwards;
    max-width: 100%;
}
@keyframes slideDown {
    from {
        opacity: 0;
        max-height: 0;
    }
    to {
        opacity: 1;
        max-height: 4000px;
    }
}
@keyframes slideUp {
    from {
        opacity: 1;
        max-height: 4000px; /* tak samo jak wyżej */
    }
    to {
        opacity: 0;
        max-height: 0;
    }
}
.spn-cnt {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}
