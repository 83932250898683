.pnf--con {
    display: flex;
    padding: 64px 180px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    align-self: stretch;
}
.pnf--sec {
    display: flex;
    padding: 24px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    align-self: stretch;
}
.img-p-n-f {
    width: 50%;
    height: auto;
    z-index: 0;
}
/* OnTablet */
@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .pnf--con {
        padding: 64px 80px;
    }
}
/* OnMobile */
@media only screen and (min-width: 0px) and (max-width: 767px) {
    .pnf--con {
        padding: 64px 24px;
    }
}

