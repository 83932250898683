.location-detail--sec {
    display: flex;
    justify-content: flex-start;
    /* justify-content: space-between; */
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px 12px;
    width: 100%;
    padding: 1px;
    /* overflow-y: auto; */
    /* white-space: nowrap; */

    overflow-y: hidden;
    word-wrap: break-word; /* Łamie długie słowa i przenosi je do nowej linii */
    overflow-wrap: break-word; /* Alternatywa dla word-wrap */
    word-break: break-all; /* Łamie słowa w dowolnym miejscu, zapewniając, że nie wyjdą poza ustaloną szerokość */
}
.location-details--hdr {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    font-family: 'Asap';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;

    color: #000732;
    
    width: 100%;
}
.location-details--hdr h3 {
    /* width: 101px; */
    /* height: 22px; */
    height: auto;
    
    /* Mobile-Head/H3 */
    font-family: 'Asap';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    
    color: #000732;
    
    flex: none;
    order: 0;
    flex-grow: 0;
}
.location-details--hdr h4 {
    /* width: 101px; */
    /* height: 22px; */
    height: auto;
    
    /* Mobile-Head/H3 */
    font-family: 'Asap';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    
    color: #000732;
    
    flex: none;
    order: 0;
    flex-grow: 0;
}
.location-details--sec-wrp {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    /* width: 282px; */
    /* height: 32px; */
    height: auto;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.location-details--sec-wrp-g {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 12px;
    
    /* width: 113px; */
    /* height: 14px; */
    height: auto;
    
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.location-details--sec-wrp-g-i {
    width: 22px;
    /* height: 22px; */
    height: auto;
    
    flex: none;
    order: 0;
    flex-grow: 0;
}
.location-details--sec-wrp-g-d {
    /* width: 260px; */
    /* height: 24px; */
    height: auto;
    
    font-family: 'Asap';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    
    color: #000732;
    
    flex: none;
    order: 0;
    flex-grow: 0;

    word-wrap: break-word;

    max-width: 520px;
}

/* Opcje dostawy/Parking */
.location-details--ohp-sec {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    padding: 4px;

    max-width: 560px;
}
.location-details--ohp-sec-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 18px 12px;
    gap: 22px;
    background: var(--white, #FBFBFF);
    /* box-shadow: 0px -1px 2px 2px rgba(0, 21, 93, 0.10), -1px 0px 0px 0px rgba(0, 21, 93, 0.10), 1px 0px 0px 0px rgba(0, 21, 93, 0.10), 0px 1px 0px 0px rgba(0, 21, 93, 0.05), 0px 1px 1px 0px rgba(0, 21, 93, 0.01), 0px 2px 1px 0px rgba(0, 21, 93, 0.00); */
    box-shadow: 0px 3px 6px rgba(0, 21, 93, 0.12), 0px 1px 3px rgba(0, 21, 93, 0.08);
    border-radius: 14px;
    height: 20px;
    font-family: 'Asap';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 145%;
    color: #000732;
    margin-top: 4px;
    flex-grow: 1;
    flex-basis: auto; /* lub ustal konkretną wartość procentową mniejszą niż 50% */
}
.location-details--ohp-sec-item:hover {
    border: 0.5px solid #ABC4F0;
    background-color: #ABC4F0;
    /* filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%); */
    /* background: #000732; */
    /* color: #FBFBFF; */
    padding: 17.5px 11.5px;
}

/* Payments */
.location-details--payments {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0px;
    gap: 12px;
    
    /* width: 113px; */
    /* height: 14px; */
    height: auto;
    
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.location-details--payments-d {
    /* height: 24px; */
    height: auto;
    
    font-family: 'Asap';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    
    color: #000732;
    
    flex: none;
    order: 0;
    flex-grow: 0;

    word-wrap: break-word;
}

/* DeliveryOptions */
.location-details--do-sec-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 18px 12px;
    /* margin-bottom: 8px; */
    gap: 22px;

    /* width: 214px;
    height: 40px; */

    background: var(--white, #FBFBFF);
    /* box-shadow: 0px 1px 1px rgba(0, 21, 93, 0.01), 0px 1px 0px rgba(0, 21, 93, 0.05), 1px 0px 0px rgba(0, 21, 93, 0.1), -1px 0px 0px rgba(0, 21, 93, 0.1), 0px -1px 0px rgba(0, 21, 93, 0.1); */
    box-shadow: 0px 3px 6px rgba(0, 21, 93, 0.12), 0px 1px 3px rgba(0, 21, 93, 0.08);
    border-radius: 14px;
    
    height: 20px;

    font-family: 'Asap';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 145%;
    /* or 20px */

    /* Black */
    color: #000732;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

    margin-top: 4px;
}
.location-details--do-sec-item:hover {
    border: 0.5px solid #ABC4F0;
    /* filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%); */
    /* background: #000732; */
    /* color: #FBFBFF; */
    padding: 17.5px 11.5px;
}
.location-details--do-sec-checkbox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 3px 0px;
    gap: 24px;

    /* width: 282px; */
    /* height: 32px; */
    height: auto;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.location-details--do-sec-checkbox-d {
    /* height: 24px; */
    height: auto;
    
    font-family: 'Asap';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */
    
    color: #000732;
    
    flex: none;
    order: 0;
    flex-grow: 0;

    word-wrap: break-word;
}

.location-details--sm {
    /* Social Media */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;

    /* width: 282px; */
    height: 24px;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.location-details--ico {
    width: 22px;
    /* height: 22px; */
    height: auto;
    margin-right: 4px;
    
    flex: none;
    order: 0;
    flex-grow: 0;
    place-items: center;
}
.location-details--fb {
    /* facebook-app-icon 1 */

    /* width: 24px;
    height: 24px; */

    border-radius: 6px;

    flex: none;
    order: 0;
    flex-grow: 0;

    cursor: pointer;
}
.location-details--fb-ico {
    background-color: #40466a;
    /* width: 24px;
    height: 24px; */
    padding-top: 4px;

    border-radius: 6px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}
.location-details--fbmw {
    /* width: 24px;
    height: 24px; */
    
    flex: none;
    order: 0;
    flex-grow: 0;

    cursor: pointer;
}

.location-details--tags {
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 6px 12px;
    gap: 10px;

    /* width: 136px; */
    /* height: 32px; */

    /* background: #FBFBFF; */
    /* border: 2px solid #000732;
    border-radius: 14px; */

    flex: none;
    order: 0;
    flex-grow: 0;
    text-align: center;
}
.location-details--tags-t {
    /* width: 112px; */
    height: 24px;
    width: auto;
    padding: 0px 12px;

    /* Body/M-Body2 */
    font-family: 'Asap';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 145%;
    /* or 20px */

    /* Black */
    color: #000732;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

    border: 1px solid #000732;
    border-radius: 14px;

    background: var(--white, #FBFBFF);
    box-shadow: 0px 3px 6px rgba(0, 21, 93, 0.12), 0px 1px 3px rgba(0, 21, 93, 0.08);
}
.location-details--tags-t:hover {
    /* opacity: 0.5; */
    border: 1px solid #ABC4F0;
    color: #000732;
    background: #ABC4F0;
}

/* LocationMap */
.location-map--sec-l-mai {
    gap: 4px!important;
}

.location--img {
    width: 100%;
    height: auto;
    /* object-fit: fill; */
    object-fit: cover;
    border-radius: 14px;
    box-shadow: 2px 0px 14px 0px rgba(0, 0, 0, 0.08);
}

/* OnTablet */
@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .location-details--ohp-sec {    
        max-width: 420px;
    }
    .location-details--sec-wrp-g-d {
        max-width: 440px;
    }
}
/* OnMobile */
@media only screen and (min-width: 0px) and (max-width: 767px) {
    .location-details--ohp-sec {    
        max-width: 320px;
    }
    .location-details--sec-wrp-g-d {
        max-width: 260px;
    }
} 
