.opening-hours--sec-checkbox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 3px 0px;
    gap: 24px;

    /* width: 282px; */
    /* height: 32px; */
    height: auto;

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.opening-hours--sec-checkbox-d {
    /* height: 24px; */
    height: auto;
    
    font-family: 'Asap';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */
    
    color: #000732;
    
    flex: none;
    order: 0;
    flex-grow: 0;

    word-wrap: break-word;
}
.opening-hours--sec-hdr {
       /* height: 24px; */
       height: auto;
    
       font-family: 'Asap';
       font-style: normal;
       font-weight: 500;
       font-size: 16px;
       line-height: 150%;
       /* identical to box height, or 24px */
       
       color: #000732;
       
       flex: none;
       order: 0;
       flex-grow: 0;
   
       word-wrap: break-word; 
       padding-bottom: 14px;
}
.opening-hours--sec-txt {
    /* height: 24px; */
    height: auto;
 
    font-family: 'Asap';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    /* identical to box height, or 24px */
    
    color: #40466a;
    
    flex: none;
    order: 0;
    flex-grow: 0;

    word-wrap: break-word; 
}
.opening-hours--sec {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;

    overflow-y: auto;
    white-space: nowrap;
    width: 100%;
    overflow-y: hidden;
    padding: 4px;
}
.opening-hours--sec-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 18px 12px;
    /* margin-bottom: 8px; */
    gap: 22px;

    /* width: 214px;
    height: 40px; */

    background: var(--white, #FBFBFF);
    /* box-shadow: 0px 1px 1px rgba(0, 21, 93, 0.01), 0px 1px 0px rgba(0, 21, 93, 0.05), 1px 0px 0px rgba(0, 21, 93, 0.1), -1px 0px 0px rgba(0, 21, 93, 0.1), 0px -1px 0px rgba(0, 21, 93, 0.1); */
    box-shadow: 0px -1px 0px 0px rgba(0, 21, 93, 0.10), -1px 0px 0px 0px rgba(0, 21, 93, 0.10), 1px 0px 0px 0px rgba(0, 21, 93, 0.10), 0px 1px 0px 0px rgba(0, 21, 93, 0.05), 0px 1px 1px 0px rgba(0, 21, 93, 0.01), 0px 2px 1px 0px rgba(0, 21, 93, 0.00);
    box-shadow: 0px 3px 6px rgba(0, 21, 93, 0.12), 0px 1px 3px rgba(0, 21, 93, 0.08);

    border-radius: 14px;
    
    height: 20px;

    font-family: 'Asap';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 145%;
    /* or 20px */

    /* Black */
    color: #000732;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

    margin-top: 4px;
}
.opening-hours--sec-item:hover {
    border: 0.5px solid #ABC4F0;
    background-color: #ABC4F0;
    /* filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%); */
    /* background: #000732; */
    /* color: #FBFBFF; */
    padding: 17.5px 11.5px;
}
.opening-hours--ico {
    width: 22px;
    /* height: 22px; */
    height: auto;
    margin-right: 4px;
    
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom: 4px;
}
